.table-orders {
  & > :not(caption) > * > * {
    padding: .25rem;
    border-bottom-color: $body-bg;
  }
  thead {
    tr {
      th {
        background-color: $body-bg;
        color: $text-muted;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  tbody {
    tr.error-msg {
      color: $text-muted;
    }
  }
}
