#main-layout {
  > div:first-child,
  #assets-card,
  .full-page {
    padding-bottom: 90px;
  }
}

#main-layout {
  > div:first-child {
    min-height: calc(100dvh + 1px);
    touch-action: pan-y;
  }
}

#main-layout {
  display: block;
  overflow-y: scroll;
  height: 100dvh;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// Toaster padding
#main-layout + div {
  top: 45px !important;
}


#root > #bottom-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 3px 0 0 0;
  position: fixed;
  z-index: 1013;
  height: 62px;
  border-radius: 20px;
  bottom: 25px;
  width: 95%;
  max-width: 570px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(#1F1F1FEB, 0.85);
  //background: linear-gradient(180deg, rgba(#1F1F1FEB, 0.75) 30%, #1d1d1d 100%);
  backdrop-filter: blur(5px);
  top: unset !important;
  min-height: unset !important;

  //&.with-notch {
  //  padding-bottom: 30px;
  //  height: 85px;
  //  transition: all .3s ease;
  //  &.minimize {
  //    padding-bottom: 0;
  //    height: 55px;
  //  }
  //}

  .btn.btn-link {
    &.tx-white:focus,
    &.tx-white:active,
    &.tx-white:hover {
      color: #fff;
    }
    &.tx-muted:focus,
    &.tx-muted:active,
    &.tx-muted:hover {
      color: $text-muted;
    }
  }
}

.card-blur {
  background-size: 0;
  background-color: rgba($gray-400, 0.08);
  border: 1px solid $secondary;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    filter: blur(10px);
    transform: scale(1.3);
    opacity: 0.1;
    z-index: -1;
  }
}
